<template lang="html">
  <div :class="$style.term">
    <span v-if="type" :class="{[$style.operator]: true, [$style[type]]: true}">{{ operator }}</span>
    <span v-if="hasError" :title="trace">ERROR</span>
    <span v-else>{{ $i18n.format(value.value) }}</span>
  </div>
</template>

<script>

const mapping = {
  'positive': '+',
  'negative': '-',
  'factor': '×',
  'result': '='
}

export default {
  props: {
    type: {
      type: String,
      validator: x => x === null || x in mapping
    },
    value: {
      type: Object
    }
  },
  computed: {
    operator () {
      return mapping[this.type]
    },
    hasError () {
      return !this.value || this.value.hasError
    },
    trace () {
      return this.value && this.value.errorTrace && this.value.errorTrace.join('\n')
    }
  }
}
</script>

<style lang="scss" module>
.term {
  display: flex;
  align-items: center;
}

.operator {
  margin-right: 0.25em;

  &.positive {
    color: green;
  }

  &.negative {
    color: red;
  }

  &.factor {
    color: blue;
  }

  &.result {
    color: gray;
  }
}
</style>
