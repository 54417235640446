<template lang="html">
  <NutrientBalanceTable :columns="columns" :details="details" />
</template>

<script>
import NutrientBalanceTable from './NutrientBalanceTable'

export default {
  components: {
    NutrientBalanceTable
  },
  props: {
    details: Object
  },
  computed: {
    columns () {
      return {
        name: 'dlgCert.certificatePreview.nutrientBalance.labels.field',
        area: 'dlgCert.certificatePreview.nutrientBalance.labels.hectareArea',
        supplyFromFixation: 'dlgCert.certificatePreview.nitrogenBalance.labels.supplyFromFixation',
        supplyFromOrganicFertilization: 'dlgCert.certificatePreview.nitrogenBalance.labels.supplyFromOrganicFertilization',
        supplyFromMineralicFertilization: 'dlgCert.certificatePreview.nitrogenBalance.labels.supplyFromMineralicFertilization',
        withdrawalByCrops: 'dlgCert.certificatePreview.nitrogenBalance.labels.withdrawalByCrops',
        nitrogenBalance: 'dlgCert.certificatePreview.nutrientBalance.labels.nitrogenBalance',
        detailedClassification: 'dlgCert.certificatePreview.nutrientBalance.labels.classification'
      }
    }
  }
}
</script>

<style lang="scss" module>
</style>
