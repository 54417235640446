<template lang="html">
  <div :class="$style.results">
    <h4 :class="$style.header">
      {{ Object.keys(results).length }} Einzelergebnisse
    </h4>
    <AutomaticPartialResult
      v-for="(employeeResult, employeeId) in results" :key="employeeId"
      :class="$style.result"
      :result="employeeResult" :name="employeeId"
    >
      Mitarbeiter {{ employeeId }}
    </AutomaticPartialResult>
  </div>
</template>

<script>
import AutomaticPartialResult from '@dlg-cert/components/preview/AutomaticPartialResult'

export default {
  components: {
    AutomaticPartialResult
  },
  props: {
    results: Object // {[employeeId]: employeeResult, ...}
  }
}
</script>

<style lang="scss" module>
.results {
  display: flex;
  flex-direction: column;

  padding: 0.5em;
  border: 1px solid black;
}

.header {
  margin: 0;
}

.result:not(:last-child) {
  margin-bottom: 0.5em;
}
</style>
