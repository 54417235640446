<template lang="html">
  <div>
    <IndicatorDetailsHeader :details="details" />

    <SimpleTable
      :columns="columns" :data="rows"
      localize-headers
    >
      <template #fieldId="{value}">
        {{ value }}
      </template>
      <template #area="{value: {squareMeters}}">
        {{ $i18n.format(squareMeters, 'area') }}
      </template>

      <template #gainsFromCrops="{value: {renormalizedHumusValue: {relativeValue}}}">
        <FormulaTerm :value="relativeValue" />
      </template>
      <template #gainsFromOrganicFertilization="{value: {renormalizedHumusValue: {relativeValue}}}">
        <FormulaTerm type="positive" :value="relativeValue" />
      </template>
      <template #gainsFromCropResidues="{value: {renormalizedHumusValue: {relativeValue}}}">
        <FormulaTerm type="positive" :value="relativeValue" />
      </template>
      <template #depletionFromCrops="{value: {renormalizedHumusValue: {relativeValue}}}">
        <FormulaTerm type="negative" :value="relativeValue" />
      </template>

      <template #humusBalance="{value}">
        <FormulaTerm type="result" :value="value" />
      </template>
      <template #detailedClassification="{value}">
        <SustainabilityClassification :value="value" />
      </template>
    </SimpleTable>

    <RawDataDisplay :data="details" />
  </div>
</template>

<script>
import {sortBy} from 'lodash'
import SimpleTable from '@components/table/SimpleTable'

import IndicatorDetailsHeader from '@dlg-cert/components/preview/details/IndicatorDetailsHeader'
import RawDataDisplay from '@dlg-cert/components/preview/details/RawDataDisplay'
import SustainabilityClassification from '@dlg-cert/components/preview/SustainabilityClassification'

import FormulaTerm from './FormulaTerm'

export default {
  components: {
    IndicatorDetailsHeader,
    RawDataDisplay,
    SimpleTable,
    FormulaTerm,
    SustainabilityClassification
  },
  props: {
    details: Object
  },
  computed: {
    rows () {
      return sortBy(this.details.fieldResults, ['name'])
    },
    columns () {
      return {
        name: 'field',
        area: 'area',
        gainsFromCrops: 'H_HM',
        gainsFromOrganicFertilization: 'H_OD',
        gainsFromCropResidues: 'H_SD',
        depletionFromCrops: 'H_HZ',
        humusBalance: 'balance',
        detailedClassification: 'classification'
      }
    }
  }
}
</script>

<style lang="scss" module>

</style>
