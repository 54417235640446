<template lang="html">
  <span :class="{[$style.classification]: true, [$style[`grade${value && value.classification}`]]: !hasError, [$style.error]: hasError}" :title="trace">
    <span v-if="hasError">
      -
    </span>
    <span v-else>{{ value.classification > 0 ? value.classification : '' }}</span>
  </span>
</template>

<script>
export default {
  props: {
    value: Object
  },
  computed: {
    hasError () {
      return !this.value || this.value.hasError
    },
    trace () {
      return this.value && this.value.underlyingTerm.errorTrace && this.value.underlyingTerm.errorTrace.join('\n')
    }
  }
}
</script>

<style lang="scss" module>
.classification {
  margin-left: 1em;

  width: 1.5em;
  height: 1.5em;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;

  font-weight: bold;
  color: rgba(235, 235, 235);

  background-color: rgb(136, 136, 136);
  color: rgba(255, 255, 255, 0.5);

  &.error {
    cursor: help;
  }

  &.grade-1 {
    background-color: rgb(100, 167, 215);
  }

  &.grade1 {
    background-color: rgb(65, 170, 15);
  }

  &.grade2 {
    background-color: rgb(160, 201, 21);
  }

  &.grade3 {
    background-color: rgb(217, 204, 29);
  }

  &.grade4 {
    background-color: rgb(226, 162, 0);
  }

  &.grade5 {
    background-color: rgb(204, 118, 3);
  }

  &.grade6 {
    background-color: rgb(158, 0, 0);
  }
}
</style>
