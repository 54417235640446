var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('IndicatorDetailsHeader',{attrs:{"details":_vm.details}}),_c('SimpleTable',{attrs:{"columns":_vm.columns,"data":_vm.rows,"localize-headers":""},scopedSlots:_vm._u([{key:"fieldId",fn:function(ref){
var value = ref.value;
return [_vm._v("\n      "+_vm._s(value)+"\n    ")]}},{key:"area",fn:function(ref){
var squareMeters = ref.value.squareMeters;
return [_vm._v("\n      "+_vm._s(_vm.$i18n.format(squareMeters, 'area'))+"\n    ")]}},{key:"gainsFromCrops",fn:function(ref){
var relativeValue = ref.value.renormalizedHumusValue.relativeValue;
return [_c('FormulaTerm',{attrs:{"value":relativeValue}})]}},{key:"gainsFromOrganicFertilization",fn:function(ref){
var relativeValue = ref.value.renormalizedHumusValue.relativeValue;
return [_c('FormulaTerm',{attrs:{"type":"positive","value":relativeValue}})]}},{key:"gainsFromCropResidues",fn:function(ref){
var relativeValue = ref.value.renormalizedHumusValue.relativeValue;
return [_c('FormulaTerm',{attrs:{"type":"positive","value":relativeValue}})]}},{key:"depletionFromCrops",fn:function(ref){
var relativeValue = ref.value.renormalizedHumusValue.relativeValue;
return [_c('FormulaTerm',{attrs:{"type":"negative","value":relativeValue}})]}},{key:"humusBalance",fn:function(ref){
var value = ref.value;
return [_c('FormulaTerm',{attrs:{"type":"result","value":value}})]}},{key:"detailedClassification",fn:function(ref){
var value = ref.value;
return [_c('SustainabilityClassification',{attrs:{"value":value}})]}}])}),_c('RawDataDisplay',{attrs:{"data":_vm.details}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }