<template lang="html">
  <div :class="$style.result">
    <span>
      <slot />
    </span>

    <SustainabilityClassification
      :value="result && result.detailedClassification"
    />
  </div>
</template>

<script>
import SustainabilityClassification from '@dlg-cert/components/preview/SustainabilityClassification'

export default {
  components: {
    SustainabilityClassification
  },
  props: {
    result: Object
  }
}
</script>

<style lang="scss" module>
.result {
  display: flex;
  justify-content: space-between;
  align-items: center;

  color: rgb(61, 61, 61);
  background-color: rgba(255, 255, 255, 0.8);
  padding: 0.5em;
}
</style>
