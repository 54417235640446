var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('IndicatorDetailsHeader',{attrs:{"details":_vm.details}}),_c('SimpleTable',{attrs:{"columns":_vm.columns,"data":_vm.tableData,"highlight":_vm.highlight,"localize-headers":""},scopedSlots:_vm._u([{key:"name",fn:function(ref){
var value = ref.value;
return [(value)?_c('span',[_vm._v(_vm._s(value))]):_c('span',[_vm._v("Gesamt")])]}},{key:"area",fn:function(ref){
var squareMeters = ref.value.squareMeters;
return [_vm._v("\n      "+_vm._s(_vm.$i18n.format(squareMeters, 'area'))+"\n    ")]}},{key:"supplyFromFixation",fn:function(ref){
var value = ref.value;
return [_c('FormulaTerm',{attrs:{"value":value}})]}},{key:"supplyFromSeeds",fn:function(ref){
var value = ref.value;
return [_c('FormulaTerm',{attrs:{"type":_vm.columns.supplyFromFixation ? 'positive' : null,"value":value}})]}},{key:"supplyFromOrganicFertilization",fn:function(ref){
var value = ref.value;
return [_c('FormulaTerm',{attrs:{"type":"positive","value":value}})]}},{key:"supplyFromMineralicFertilization",fn:function(ref){
var value = ref.value;
return [_c('FormulaTerm',{attrs:{"type":"positive","value":value}})]}},{key:"withdrawalByCrops",fn:function(ref){
var value = ref.value;
return [_c('FormulaTerm',{attrs:{"type":"negative","value":value}})]}},{key:"withdrawalCorrectionFactor",fn:function(ref){
var value = ref.value;
return [_c('FormulaTerm',{attrs:{"type":"factor","value":value}})]}},{key:"nitrogenBalance",fn:function(ref){
var value = ref.value;
return [_c('FormulaTerm',{attrs:{"type":"result","value":value}})]}},{key:"phosphorusBalance",fn:function(ref){
var value = ref.value;
return [_c('FormulaTerm',{attrs:{"type":"result","value":value}})]}},{key:"detailedClassification",fn:function(ref){
var value = ref.value;
return [_c('SustainabilityClassification',{attrs:{"value":value}})]}}])}),_c('RawDataDisplay',{attrs:{"data":_vm.details}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }