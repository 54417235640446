<template lang="html">
  <div :class="$style.details" :style="{'--pillar-color': `var(--pillar-${$route.params.pillar})`}">
    <h3>
      <IxButton back @click="back">
        <IxRes>dlgCert.navigation.buttons.backToPreview</IxRes>
      </IxButton>
      <!-- TODO change resource key to dlgCert.headers.{{ pillar }}.{{ indicator }} once translations can be easily moved -->
      Details zu <IxRes>dlgCert.headers.{{ pillar }}_{{ indicator }}</IxRes>
    </h3>
    <NitrogenBalance v-if="indicator === 'nitrogenBalance'" :details="details" />
    <PhosphorusBalance v-else-if="indicator === 'phosphorusBalance'" :details="details" />
    <HumusBalance v-else-if="indicator === 'humusBalance'" :details="details" />
    <GenericIndicatorDetails
      v-else
      :pillar="pillar"
      :indicator="indicator"
      :details="details"
    />
  </div>
</template>

<script>
import IxButton from '@components/IxButton'

import GenericIndicatorDetails from './GenericIndicatorDetails'
import NitrogenBalance from './ecology/NitrogenBalance'
import PhosphorusBalance from './ecology/PhosphorusBalance'
import HumusBalance from './ecology/HumusBalance'

export default {
  components: {
    IxButton,
    NitrogenBalance,
    PhosphorusBalance,
    HumusBalance,
    GenericIndicatorDetails
  },
  props: {
    pillar: String,
    indicator: String,
    data: Object
  },
  computed: {
    details () {
      return this.data[`${this.pillar}Result`][`${this.indicator}Result`]
    }
  },
  methods: {
    back () {
      this.$router.push({name: 'certificatePreview'})
    }
  }
}
</script>

<style lang="scss" module>
.details {
  display: flex;
  flex-direction: column;
}
</style>
