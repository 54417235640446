<template lang="html">
  <div :class="$style.result">
    <span :class="$style.label">
      <slot />
    </span>

    <span v-if="hasError" :class="$style.error" :title="trace">
      -
    </span>
    <span v-else :class="$style.value">
      <slot name="value" :value="value">
        <template v-if="value.value !== undefined">
          {{ $i18n.format(value.value, 'f2') }}
        </template>

        <span :class="$style.unit">
          <slot name="unit" />
        </span>
      </slot>
    </span>
  </div>
</template>

<script>
export default {
  props: {
    value: Object
  },
  computed: {
    hasError () {
      return !this.value || this.value.hasError
    },
    trace () {
      return this.value && (this.value.underlyingTerm || this.value).errorTrace && (this.value.underlyingTerm || this.value).errorTrace.join('\n')
    }
  }
}
</script>

<style lang="scss" module>
.result {
  display: flex;
  justify-content: space-between;
  align-items: center;

  color: rgb(61, 61, 61);
  padding: 0.5em;
}

.value, .error {
  margin-left: 1em;
  display: flex;
  justify-content: center;
  align-items: center;
  // for consistency with ClassificationResult
  height: 1.5em;

  border: 1px solid rgb(144, 144, 144);
  padding: 0 0.5em;
}

.error {
  cursor: help;
}

.label {

}

.unit {

}
</style>
