<template lang="html">
  <NumericResultBase :value="result ? result.aggregate : null">
    <slot />

    <template #unit>
      €
    </template>
  </NumericResultBase>
</template>

<script>
import NumericResultBase from './NumericResultBase'

export default {
  components: {
    NumericResultBase
  },
  props: {
    result: Object
  }
}
</script>

<style lang="scss" module>
</style>
