<template lang="html">
  <ScoreResult
    v-if="result && result.detailedScore !== undefined"
    :result="result"
  >
    <slot />
  </ScoreResult>
  <EmissionsResult
    v-else-if="result && result.relativeEmissions !== undefined"
    :result="result"
  >
    <slot />
  </EmissionsResult>
  <ClassificationResult
    v-else-if="result && result.detailedClassification !== undefined"
    :result="result"
  >
    <slot />
  </ClassificationResult>
  <EconomyAggregateResult
    v-else-if="result && result.aggregate !== undefined"
    :result="result"
  >
    <slot />
  </EconomyAggregateResult>
</template>

<script>
import ScoreResult from '@dlg-cert/components/preview/ScoreResult'
import EmissionsResult from '@dlg-cert/components/preview/EmissionsResult'
import ClassificationResult from '@dlg-cert/components/preview/ClassificationResult'
import EconomyAggregateResult from '@dlg-cert/components/preview/EconomyAggregateResult'

export default {
  components: {
    ScoreResult,
    EmissionsResult,
    ClassificationResult,
    EconomyAggregateResult
  },
  props: {
    result: {}
  }
}
</script>

<style lang="scss" module>
</style>
