<template lang="html">
  <div :class="$style.header">
    <div :class="$style.lines">
      <ClassificationResult v-if="details.detailedClassification !== undefined" :result="details">
        Gesamtnote
      </ClassificationResult>
      <EmissionsResult v-if="details.relativeEmissions !== undefined" :result="details">
        Gesamtemissionen
      </EmissionsResult>
      <ScoreResult v-if="details.detailedScore !== undefined" :result="details">
        Gesamtpunkte
      </ScoreResult>
      <EconomyAggregateResult v-if="details.aggregate !== undefined" :result="details">
        Gesamtbetrag
      </EconomyAggregateResult>
    </div>
  </div>
</template>

<script>
import ScoreResult from '@dlg-cert/components/preview/ScoreResult'
import EmissionsResult from '@dlg-cert/components/preview/EmissionsResult'
import ClassificationResult from '@dlg-cert/components/preview/ClassificationResult'
import EconomyAggregateResult from '@dlg-cert/components/preview/EconomyAggregateResult'

export default {
  components: {
    EmissionsResult,
    ClassificationResult,
    EconomyAggregateResult,
    ScoreResult
  },
  props: {
    details: Object
  }
}
</script>

<style lang="scss" module>
.header {
  display: flex;

  font-size: 1.5em;
  margin-bottom: 0.5em;
}

.lines {
  display: flex;
  flex-direction: column;
}
</style>
