<template lang="html">
  <div :class="$style.preview">
    <IndicatorDetailsHeader :details="details" />

    <div :class="$style.partials">
      <template v-for="(result, key) in details">
        <IndividualEmployeeResults
          v-if="key === 'individualResults'" :key="key"
          :class="$style.result"
          :results="result"
        />
        <AutomaticPartialResult
          v-else :key="key"
          :class="$style.result"
          :result="result"
        >
          <IxRes>dlgCert.headers.{{ pillar }}.{{ indicator }}.{{ key.replace(/(ScoreResult|Result)$/, '') }}</IxRes>
        </AutomaticPartialResult>
      </template>
    </div>

    <RawDataDisplay :data="details" />
  </div>
</template>

<script>
import AutomaticPartialResult from '@dlg-cert/components/preview/AutomaticPartialResult'
import RawDataDisplay from '@dlg-cert/components/preview/details/RawDataDisplay'

import IndicatorDetailsHeader from './IndicatorDetailsHeader'
import IndividualEmployeeResults from './IndividualEmployeeResults'

export default {
  components: {
    IndicatorDetailsHeader,
    IndividualEmployeeResults,
    AutomaticPartialResult,
    RawDataDisplay
  },
  props: {
    pillar: String,
    indicator: String,
    details: Object
  }
}
</script>

<style lang="scss" module>
.preview {
  display: flex;
  flex-direction: column;
}

.partials {
  // width: 30%;
  align-self: flex-start;
  display: flex;
  flex-direction: column;
}

.result:not(:last-child) {
  margin-bottom: 0.5em;
}
</style>
