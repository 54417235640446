<template lang="html">
  <div>
    <IndicatorDetailsHeader :details="details" />

    <SimpleTable
      :columns="columns" :data="tableData"
      :highlight="highlight" localize-headers
    >
      <template #name="{value}">
        <span v-if="value">{{ value }}</span>
        <span v-else>Gesamt</span>
      </template>
      <template #area="{value: {squareMeters}}">
        {{ $i18n.format(squareMeters, 'area') }}
      </template>

      <template #supplyFromFixation="{value}">
        <FormulaTerm :value="value" />
      </template>
      <template #supplyFromSeeds="{value}">
        <FormulaTerm :type="columns.supplyFromFixation ? 'positive' : null" :value="value" />
      </template>
      <template #supplyFromOrganicFertilization="{value}">
        <FormulaTerm type="positive" :value="value" />
      </template>
      <template #supplyFromMineralicFertilization="{value}">
        <FormulaTerm type="positive" :value="value" />
      </template>

      <template #withdrawalByCrops="{value}">
        <FormulaTerm type="negative" :value="value" />
      </template>
      <template #withdrawalCorrectionFactor="{value}">
        <FormulaTerm type="factor" :value="value" />
      </template>

      <template #nitrogenBalance="{value}">
        <FormulaTerm type="result" :value="value" />
      </template>
      <template #phosphorusBalance="{value}">
        <FormulaTerm type="result" :value="value" />
      </template>
      <template #detailedClassification="{value}">
        <SustainabilityClassification :value="value" />
      </template>
    </SimpleTable>

    <RawDataDisplay :data="details" />
  </div>
</template>

<script>
import {omit, mapValues, sumBy, sortBy} from 'lodash'
import SimpleTable from '@components/table/SimpleTable'

import IndicatorDetailsHeader from '@dlg-cert/components/preview/details/IndicatorDetailsHeader'
import RawDataDisplay from '@dlg-cert/components/preview/details/RawDataDisplay'
import SustainabilityClassification from '@dlg-cert/components/preview/SustainabilityClassification'

import FormulaTerm from './FormulaTerm'

export default {
  components: {
    IndicatorDetailsHeader,
    RawDataDisplay,
    SimpleTable,
    FormulaTerm,
    SustainabilityClassification
  },
  props: {
    columns: Object,
    details: Object
  },
  computed: {
    tableData () {
      var results = sortBy(this.details.fieldResults, ['name'])
      if (results.length === 0) return []

      const detailedClassification = this.details.detailedClassification

      const summaryColumns = omit(this.columns, ['fieldId', 'name'])
      const summary = mapValues(summaryColumns, (value, key) => {
        if (key === 'area') {
          return {
            squareMeters: sumBy(results, 'area.squareMeters'),
            hectares: sumBy(results, 'area.hectares')
          }
        } else if (key === 'detailedClassification') {
          return detailedClassification
        } else {
          return {
            value: sumBy(results, `${key}.value`)
          }
        }
      })

      return results.concat(summary)
    }
  },
  methods: {
    highlight (row) {
      if (!row.fieldId) return [this.$style.summary]
    }
  }
}
</script>

<style lang="scss" module>
.summary {
  font-weight: bold;
}
</style>
