<template lang="html">
  <div :class="$style.raw">
    <a href="#" @click.prevent="show = !show">
      <span v-if="show">Rohdaten ausblenden</span>
      <span v-else>Rohdaten anzeigen</span>
    </a>

    <wip v-if="show">
      <code>
        <pre>{{ JSON.stringify(data, null, 2) }}</pre>
      </code>
    </wip>
  </div>
</template>

<script>
export default {
  props: {
    data: Object
  },
  data () {
    return {
      show: false
    }
  }
}
</script>

<style lang="scss" module>
.raw {
  margin-top: 1em;
}
</style>
